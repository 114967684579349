import React, { useState, useReducer, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ChromePicker } from 'react-color';
// import { SketchPicker, ChromePicker } from 'react-color';

import SignUp from '../tiles/SignUp';

const Container = styled.div`
  margin: 1rem auto;
  max-width: 600px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;
`;
const ColorSwatch = styled.button`
  flex-grow: 1;
  min-height: 20px;
  min-width: 20px;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
`;

const Color = () => {
  const pickerNode = useRef();
  const swatchesNode = useRef();
  const initialState = [
    {
      active: false,
      color: {
        hex: '#060628',
        hsl: { h: 240, s: 0.7396999999999999, l: 0.09, a: 1 },
      },
      key: '900',
    },
    {
      active: false,
      color: {
        hex: '#100E53',
        hsl: { h: 241.99999999999997, s: 0.7100000000000001, l: 0.19, a: 1 },
      },
      key: '800',
    },
    {
      active: false,
      color: {
        hex: '#2B227C',
        hsl: { h: 245.99999999999997, s: 0.57, l: 0.31, a: 1 },
      },
      key: '700',
    },
    {
      active: false,
      color: {
        hex: '#5742BD',
        hsl: { h: 249.99999999999994, s: 0.48, l: 0.5, a: 1 },
      },
      key: '500',
    },
    {
      active: false,
      color: {
        hex: '#a28ae5',
        hsl: { h: 256, s: 0.6399000000000004, l: 0.72, a: 1 },
      },
      key: '300',
    },
    {
      active: false,
      color: {
        hex: '#E8E4F2',
        hsl: { h: 256.99999999999994, s: 0.3499999999999991, l: 0.92, a: 1 },
      },
      key: '200',
    },
    {
      active: false,
      color: {
        hex: '#f8f5ff',
        hsl: { h: 260.00000000000006, s: 0.9700000000000004, l: 0.99, a: 1 },
      },
      key: '100',
    },
  ];
  const [pickerOpened, setOpened] = useState(false);
  const reducer = (state, action) => {
    switch (action.type) {
      case 'activate': {
        return state.map((swatch, idx) => {
          if (idx === action.idx) return { ...swatch, active: true };
          return { ...swatch, active: false };
        });
      }
      case 'setColor': {
        return state.map(swatch => {
          if (swatch.active)
            return {
              ...swatch,
              color: { hex: action.color.hex, hsl: action.color.hsl },
            };
          return swatch;
        });
      }
      default: {
        return state;
      }
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);
  const handleClick = e => {
    if (!pickerNode.current) return; //picker is close, stays closed
    if (
      pickerNode.current.contains(e.target) ||
      swatchesNode.current.contains(e.target)
    ) {
      // inside picker or swatches click
      return;
    }
    // outside picker or swatches click
    setOpened(false);
  };

  const handleColorChange = color => {
    dispatch({ type: 'setColor', color });
  };

  const activeColor = state.reduce(
    (prev, swatch) => {
      if (swatch.active) return swatch.color;
      return prev;
    },
    { hex: '#fff' },
  );
  const color = state.reduce(
    (prev, swatch) => ({ ...prev, [swatch.key]: swatch.color.hex }),
    {},
  );
  return (
    <>
      <Container
        ref={swatchesNode}
        css={`
          height: 5rem;
        `}
      >
        {state.map((swatch, idx) => (
          <ColorSwatch
            key={swatch.key}
            onClick={() => {
              dispatch({ type: 'activate', idx });
              setOpened(true);
            }}
            css={`
              background: ${swatch.color.hex};
            `}
          />
        ))}
      </Container>
      {pickerOpened && (
        <Container>
          <div ref={pickerNode}>
            <ChromePicker
              color={activeColor.hsl}
              onChangeComplete={handleColorChange}
            />
          </div>
        </Container>
      )}
      <SignUp color={color} />
    </>
  );
};

export default Color;
