import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

const color = {
  100: '#F7F7F7',
  200: '#E8E4F2',
  300: '#B6AFC5',
  500: '#5742BD',
  700: '#2B227C',
  800: '#100E53',
  900: '#060628',
};

const Form = styled.form`
  box-shadow: ${props => `0 2px 4px ${props.theme[200]}`};
  background: ${props => props.theme[100]};
  border-radius: 0.6em;
  padding: 20px;
  font-size: 15px;
  line-height: 1.5;
  font-weight: 600;
  color: ${props => props.theme[900]};
  label {
    display: block;
    margin: 1em 0 0.3em 0;
    padding: 0 0.5em;
  }
  input,
  textarea,
  select {
    width: 100%;
    padding: 0.5em;
    font-size: 1em;
    border: ${props => `1px solid ${props.theme[900]}`};
    color: ${props => props.theme[900]};
    background: ${props => props.theme[100]};
    border-color: ${props => props.theme[900]};
    border-radius: 0.3em;
    &:focus {
      outline: 0;
      border-color: ${props => props.theme[300]};
    }
  }
  input::placeholder,
  textarea::placeholder,
  select::placeholder {
    /* color: ${props => props.theme[300]};
    opacity: 1; */
    text-transform: capitalize;
  }
  button,
  input[type='submit'] {
    width: auto;
    background: ${props => props.theme[500]};
    color: ${props => props.theme[200]};
    border: none;
    border-radius: 0.3em;
    font-size: 15px;
    font-weight: 600;
    padding: 0.5em;
    margin: 1em 0 0.3em 0;
  }
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;

    &::before {
      height: 5px;
      content: '';
      display: block;
      background-image: ${props => `linear-gradient(
        to right,
        ${props.theme['500']} 0%,
        ${props.theme['900']} 50%,
        ${props.theme['500']} 100%
      )`};
    }
  }
`;

const SignUp = props => {
  return (
    <ThemeProvider theme={props.color || color}>
      <Form>
        <fieldset>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Enter your Email"
          />
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="Enter a strong password"
          />
          <button type="submit">Sign In</button>
        </fieldset>
      </Form>
    </ThemeProvider>
  );
};

export default SignUp;
